import React from "react"

import { GatsbyLocation } from "local-types"
import SEO from "../components/Seo/Seo"
import styled from "styled-components"
import { device } from "../styles/constants"
import { Row, Col } from "antd"

import Layout from "../components/Layout"
import SectionLayout from "../components/WrapperSections/SectionLayout"
import MapContact from "../components/ContactComponents/ContactMap"
import FooterContact from "../components/ContactComponents/FooterContact"
import FormContact from "../components/ContactComponents/FormContact"

import { useSiteMetadata } from "../hooks"

const contactMetadata = {
  Title: "Contact Us - Flipbase",
  Keywords: ["Flipbase Contact", "Contact Flipbase"],
  Description: "How can we help you? Feel free to contact us.",
  Image: "/seo-image-general.png",
}

interface ContactPageProps {
  location: GatsbyLocation
  preivousPath?: string
  nextPath?: string
}

const ContactPage: React.FunctionComponent<ContactPageProps> = ({
  location,
  preivousPath = "/blog",
}): React.ReactElement => {
  const { title: siteTitle } = useSiteMetadata()

  return (
    <Layout location={location} title={siteTitle} preivousPath={preivousPath}>
      <SectionLayout>
        <SEO
          title={contactMetadata.Title}
          description={contactMetadata.Description}
          image={contactMetadata.Image}
          keywords={contactMetadata.Keywords}
        />
        <ContactRow>
          <MapCol xl={16} lg={14} md={24} sm={24} xs={24}>
            <MapContact />
          </MapCol>
          <Col xl={10} lg={10} md={24} sm={24} xs={24}>
            <FormContact />
          </Col>
        </ContactRow>
      </SectionLayout>
      <FooterContact
        paddingLaptop="20px 500px 20px 160px;"
        elPosition="fixed"
      />
    </Layout>
  )
}

const ContactRow = styled(Row)`
  margin-top: 64px;
  @media ${device.laptopL} {
    margin-top: 48px;
  }
  @media ${device.mobileL} {
    margin-top: 0;
  }
`

const MapCol = styled(Col)`
  margin-right: -10%;
`

export default ContactPage
