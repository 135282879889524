import React, { useState, useEffect } from "react"
import styled from "styled-components"
import FlipbaseButton from "../../FlipbaseButton"
import { colors, device } from "../../../styles/constants"
import { Form, Input } from "antd"
import axios from "axios"

const { TextArea } = Input

interface FormContactProps {}

const FormContact: React.FunctionComponent<
  FormContactProps
> = ({}): React.ReactElement => {
  const [form] = Form.useForm()
  const [successForm, setSuccessForm] = useState("")
  const [successBg, setSuccessBg] = useState("")
  const [errorForm, setErrorForm] = useState("")
  const [errorBg, setErrorBg] = useState("")
  // Input Change Handling
  const [inputs, setInputs] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
    question: "",
    language: "ENG",
  })
  const handleOnChange = event => {
    event.persist()
    setSuccessForm("")
    setErrorForm("")
    setInputs(prev => ({
      ...prev,
      [event.target.id]: event.target.value,
    }))
  }
  const onFinish = values => {
    event.preventDefault()

    setServerState({ submitting: true })
    axios({
      method: "POST",
      url: `https://formspree.io/${process.env.GATSBY_FORMSPREE_CONTACT_FORM_ID}`,
      data: inputs,
    })
      .then(r => {
        handleServerResponse(true, "Thanks!")
        form.resetFields()
        setSuccessForm(
          "Thank you for your request. We will get back to you as soon as possible to answer your question!"
        )
        setSuccessBg("rgba(221,238,229, 0.9)")
        setTimeout(() => {
          setSuccessForm("")
          setSuccessBg("")
        }, 4000)
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error)
        setErrorForm(
          "Oops! Something went wrong with your submission, please try again."
        )
        setErrorBg("rgba(238,220,226, 0.9)")
        setTimeout(() => {
          setErrorForm("")
          setErrorBg("")
        }, 4000)
      })
  }
  // Server State Handling
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })
  const handleServerResponse = (ok, msg) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      setInputs({
        name: "",
        company: "",
        email: "",
        phone: "",
        question: "",
      })
    }
  }

  return (
    <FormWrapper>
      <FormTitle>Contact us</FormTitle>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{}}
        scrollToFirstError
      >
        <Form.Item
          name="name"
          onChange={handleOnChange}
          value={inputs.name}
          rules={[
            {
              required: true,
              message: "Please provide your full name.",
            },
          ]}
        >
          <StyledInput placeholder="Full name" />
        </Form.Item>
        <Form.Item
          name="company"
          onChange={handleOnChange}
          value={inputs.company}
          rules={[
            {
              required: true,
              message: "Please provide your company name.",
            },
          ]}
        >
          <StyledInput placeholder="Company" />
        </Form.Item>

        <Form.Item
          name="email"
          onChange={handleOnChange}
          value={inputs.email}
          rules={[
            {
              type: "email",
              message: "Please provide a valid e-mail address.",
            },
            {
              required: true,
              message: "Please provide your e-mail address.",
            },
          ]}
        >
          <StyledInput placeholder="Email" />
        </Form.Item>

        <Form.Item
          name="phone"
          onChange={handleOnChange}
          value={inputs.phone}
          rules={[
            {
              min: 9,
              max: 14,
              pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g,
              message: "Please provide a valid phone number.",
            },
            {
              required: true,
              message: "Please provide your phone number.",
            },
          ]}
        >
          <StyledInput placeholder="Phone" />
        </Form.Item>

        <Form.Item
          name="question"
          onChange={handleOnChange}
          value={inputs.question}
        >
          <StyledTextArea rows={3} placeholder="Question" />
        </Form.Item>
        <Form.Item>
          <FlipbaseButton type="secondary" htmlType="submit">
            Send
          </FlipbaseButton>
        </Form.Item>
        <SuccessForm successBg={successBg}>{successForm}</SuccessForm>
        <ErrorForm errorBg={errorBg}>{errorForm}</ErrorForm>
      </Form>
    </FormWrapper>
  )
}

const FormWrapper = styled.div`
  -webkit-box-shadow: 1px 1px 20px 0px rgba(35, 29, 48, 0.2);
  -moz-box-shadow: 1px 1px 20px 0px rgba(35, 29, 48, 0.2);
  box-shadow: 1px 1px 20px 0px rgba(35, 29, 48, 0.2);
  border-radius: 4px;
  max-width: 649px;
  width: 100%;
  background-color: #ffffff;
  padding: 8px 42px;
  box-sizing: border-box;
  margin-top: -48px;
  margin-left: 22px;
  position: relative;
  z-index: 501;
  .ant-form-item {
    @media ${device.laptopL} {
      margin-bottom: 16px;
    }
  }
  .ant-form-item-explain {
    font-size: 12px !important;
    line-height: 15px !important;
    color: ${colors.mainColor} !important;
    padding-top: 6px;
  }
  @media ${device.laptopL} {
    position: relative;
    z-index: 501;
  }
  @media ${device.tablet} {
    margin: 0 auto;
    max-width: 708px;
  }
  @media ${device.mobileL} {
    padding: 8px 24px;
  }
`

const FormTitle = styled.h4`
  color: ${colors.dark};
  font-family: FreightSans;
  font-weight: 600;
  font-size: 34px;
  line-height: 38px;
  margin-top: 16px;
  @media ${device.tablet} {
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 31px;
  }
  @media ${device.mobileL} {
    text-align: center;
    font-size: 26px;
    line-height: 29px;
  }
`

const AgreementText = styled.div`
  display: flex;
  flex-direction: row;
  height: 80px !important;
  padding-top: 32px;
  @media ${device.laptopL} {
    flex-direction: column;
  }
  @media ${device.tablet} {
    flex-direction: column;
    margin-top: 50px;
    a {
      width: 220px;
    }
  }
  @media ${device.mobileL} {
    text-align: center;
    font-size: 28px;
    line-height: 33px;
    margin-top: 16px;
    span {
      text-align: left;
    }
    a {
      width: 100%;
      max-width: 100%;
      div {
        width: 100%;
        max-width: 100%;
      }
    }
  }
  label {
    max-width: 320px;
  }
  span {
    color: rgba(35, 29, 48, 0.6);
    font-family: FreightSans;
    font-size: 14px;
    line-height: 18px;
    @media ${device.laptopL} {
      margin-bottom: 12px;
    }
    @media ${device.tablet} {
      max-width: 311px;
    }
  }

  a {
    color: rgba(35, 29, 48, 0.6);
    text-decoration: underline;
    :hover {
      color: rgba(35, 29, 48, 0.6);
    }
  }
`

const StyledTextArea = styled(TextArea)`
  font-family: FreightSans !important;
  border: none !important;
  border-bottom: 1px solid rgba(35, 29, 48, 0.1) !important;
  border-radius: 0 !important;
  font-size: 20px !important;
  line-height: 26px !important;
  padding: 6px 0 !important;
  color: rgba(35, 29, 48, 1) !important;
  ::placeholder {
    color: rgba(35, 29, 48, 0.6) !important;
    font-size: 20px !important;
    line-height: 26px !important;
    @media ${device.mobileL} {
      font-size: 18px !important;
      line-height: 23px !important;
    }
  }
  :focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    border-bottom: 1px solid rgba(35, 29, 48, 0.9) !important;
    background: none !important;
  }
  :hover {
    border-bottom: 1px solid rgba(35, 29, 48, 0.9) !important;
    border-radius: 0 !important;
    outline: none !important;
  }
`

const StyledInput = styled(Input)`
  font-family: FreightSans !important;
  border: none !important;
  border-bottom: 1px solid rgba(35, 29, 48, 0.1) !important;
  border-radius: 0 !important;
  font-size: 20px !important;
  line-height: 26px !important;
  padding: 6px 0 !important;
  color: rgba(35, 29, 48, 1) !important;
  ::placeholder {
    color: rgba(35, 29, 48, 0.6) !important;
    font-size: 20px !important;
    line-height: 26px !important;
    @media ${device.mobileL} {
      font-size: 18px !important;
      line-height: 23px !important;
    }
  }
  :focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    border-bottom: 1px solid rgba(35, 29, 48, 0.9) !important;
    background: none !important;
  }
  :hover {
    border-bottom: 1px solid rgba(35, 29, 48, 0.9) !important;
    border-radius: 0 !important;
    outline: none !important;
  }
`

const SuccessForm = styled.h4`
  color: #219653;
  font-family: FreightSans;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  width: 100%;
  height: 100%;
  padding: 60% 10% 0;
  background-color: ${props => props.successBg};
  z-index: ${({ successBg }) => {
    if (successBg === "") return "-20"
    if (successBg !== "") return "501"
  }};
  position: absolute;
  top: 0;
  right: 0;
  ::before {
    content: "";
    position: absolute;
    transform: rotate(45deg);
    width: 48px;
    height: 72px;
    top: 20%;
    display: ${({ successBg }) => {
      if (successBg === "") return "none"
      if (successBg !== "") return " inline-block"
    }};
    border-bottom: 9px solid #219653;
    border-right: 9px solid #219653;
    left: 50%;
    margin-left: -20px;
  }
`
const ErrorForm = styled.h4`
  color: ${colors.mainColor};
  font-family: FreightSans;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  width: 100%;
  height: 100%;
  padding: 60% 10% 0;
  background-color: ${props => props.errorBg};
  z-index: ${({ errorBg }) => {
    if (errorBg === "") return "-20"
    if (errorBg !== "") return "501"
  }};
  position: absolute;
  top: 0;
  right: 0;
  ::before {
    content: "x";
    position: absolute;
    font-size: 128px;
    color: #660000;
    top: 20%;
    display: ${({ errorBg }) => {
      if (errorBg === "") return "none"
      if (errorBg !== "") return " inline-block"
    }};
    left: 50%;
    margin-left: -24px;
    font-weight: 100;
  }
`

export default FormContact
