import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import { SiteSiteMetadataAuthor } from "graphql-types";
import Image from 'gatsby-image'

interface MapContactProps {
  author: SiteSiteMetadataAuthor;
}

const MapContact: React.FunctionComponent<MapContactProps> = ({
  
}): React.ReactElement => {
    const contactMap = useStaticQuery(graphql`
    query {
        file(absolutePath: {regex: "/mapContact.png/"}) {
        childImageSharp {
          fluid(maxWidth: 1100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Image fluid={contactMap.file.childImageSharp.fluid} alt="Map with logo"/>     
  );
};



export default MapContact;
